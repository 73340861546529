import { state } from '@/store'

export default {
  gmSubmit_init() {
    const refund = state.gsCommon_refund
    const refund_user = state.gsCommon_refundUser

    // form.input 에 데이터 바인딩
    state.gsSubmit_formData.refund_submit_state = refund_user.refund_submit_state || 1
    state.gsSubmit_formData.refund_user_company = refund_user.refund_user_company || ''
    state.gsSubmit_formData.refund_starting_address = refund_user.refund_starting_address || ''
    state.gsSubmit_formData.refund_starting_address_type = refund_user.refund_starting_address_type || 0
    state.gsSubmit_formData.refund_user_part = refund_user.refund_user_part || ''
    state.gsSubmit_formData.refund_user_name = refund_user.refund_user_name || ''
    state.gsSubmit_formData.refund_user_phone = refund_user.refund_user_phone || ''
    state.gsSubmit_formData.refund_bank_name = refund_user.refund_bank_name || ''
    state.gsSubmit_formData.refund_bank_code = refund_user.refund_bank_code || ''
    state.gsSubmit_formData.refund_account_number = refund_user.refund_account_number || ''
    state.gsSubmit_formData.refund_account_holder = refund_user.refund_account_holder || ''

    const define_field = refund.refund_custom_field || []
    const field_list = []
    state.gsSubmit_formData.refund_user_custom_field = field_list

    define_field.forEach(field => {
      const refs = refund_user.refund_user_custom_field || []
      const ref = refs.find(data => field.id === data.refund_custom_field_id)
      field_list.push({
        id: ref?.id || 0,
        value: ref?.value || '',
        label: field.label || '',
        refund_user_id: refund_user.id || 0,
        refund_custom_field_id: field.id,
      })
    })
  },

  // 사인 유효성 검사
  gsSubmit_signValid() {
    // 대표님 요청으로 3 이상이면 가능한걸로... (점이라도 찍으면 넘어가게 해주라는 것)
    state.gsSubmit_form.invalidSignText = state.gsSubmit_form.signLength < 3 ?
      '서명이 너무 짧거나 작아서 유효하지 않습니다.' : ''

    return Boolean(!state.gsSubmit_form.invalidSignText)
  }
}
