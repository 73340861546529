<template>
  <v-card-text
    v-if="gcCommon_refund.event"
    class="py-0"
  >
    <!--  인풋: 대행사명  -->
    <v-text-field
      :value="gcCommon_refund.use_organ_name === 0 ? gcCommon_refund.event.agency_company_name : gcCommon_refund.event.event_organ_name"
      label="준비사무국"
      color="black"
      class="mb-5 grey lighten-4"
      hide-details
      outlined
      readonly
      dense
    />
    <!--  인풋: 대행사 담당자명  -->
    <v-text-field
      :value="gcCommon_refund.event.agency_manager_name"
      label="담당자명"
      color="black"
      class="mb-5 grey lighten-4"
      hide-details
      outlined
      readonly
      dense
    />
    <!--  인풋: 대행사 담당자 연락처  -->
    <v-text-field
      :value="gcCommon_refund.event.agency_manager_phone"
      label="연락처"
      color="black"
      class="mb-5 grey lighten-4 c-agency-manager-contact"
      hide-details
      outlined
      readonly
      dense
    >
      <template v-slot:append>
        <v-btn
          v-if="isTel"
          color="blue"
          :href="`tel:${gcCommon_refund.event.agency_manager_phone}`"
          :disabled="gcSubmit_submitting"
          icon
        >
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <!--  버튼: 환급 가이드  -->
    <v-btn
      v-if="gcCommon_refund.refund_guide_enable"
      @click="onClickGuide"
      :disabled="gcSubmit_submitting"
      class="mt-7"
      color="blue"
      depressed
      outlined
      x-large
      block
    >
      {{ gcCommon_refund.refund_guide_label }}
    </v-btn>

    <v-divider class="my-7"/>

  </v-card-text>
</template>

<script>
export default {
  name: "SubmitCardInfo",

  computed: {
    isTel() {
      const value = this.gcCommon_refund.event.agency_manager_phone

      return Boolean(value.match(/^[0-9]+$/))
    }
  },

  methods: {
    // 가이드 버튼 클릭시
    onClickGuide() {
      this.gmCommon_alertHtmlOpen(this.gcCommon_refund.refund_guide_html)
    }
  }
}
</script>